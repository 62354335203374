import {useState, useMemo, useEffect} from 'react';

import {oneOfType, node, func} from 'prop-types';

import {API_ENDPOINTS, DEFAULT_WORKSPACE, SNACKBAR_ACTIONS, USERS_AUTHORIZATION_LEVELS} from '../const';
import WorkspacesContext from '../contexts/WorkspacesContext';
import useHttp from '../hooks/misc/useHttp';
import useReport from '../hooks/providers/useReport';
import useSnackbar from '../hooks/providers/useSnackbar';
import {getFileB64} from '../utils';

const WorkspacesProvider = ({children}) => {
  const {loadReports, setReports} = useReport();

  const [workspaces, setWorkspaces] = useState([DEFAULT_WORKSPACE]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(DEFAULT_WORKSPACE);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(DEFAULT_WORKSPACE.workspace_id);
  const [createReportModalOpen, setCreateReportModalOpen] = useState(false);
  const [workspacesTabsMenuAchorEl, setWorkspacesTabsMenuAchorEl] = useState(null);
  const [importReportError, setImportReportError] = useState('');
  const [isCustomReportCreation, setIsCustomReportCreation] = useState(false);
  const [deleteWorkspaceOrReportModalOpen, setDeleteWorkspaceOrReportModalOpen] = useState(false);
  const [anchorElReportTabsMenu, setAnchorElReportTabsMenu] = useState(null);
  const [rightClickedReportTab, setRightClickedReportTab] = useState(null);
  const [isExistingWorkspaceEdition, setIsExistingWorkspaceEdition] = useState(false);
  const [createOrUpdateWorkspaceModalOpen, setCreateOrUpdateWorkspaceModalOpen] = useState(false);
  const [rightClickedWorkspace, setRightClickedWorkspace] = useState(null);
  const [isWorkspaceUsersModalOpen, setIsWorkspaceUsersModalOpen] = useState(false);

  const deleteWorkspaceFromProvider = workspaceId => {
    setWorkspaces(currentWorkspaces => currentWorkspaces.filter(w => w.workspace_id !== workspaceId));
  };

  const addReportToWorkspace = (workspaceId, reportName, reportId, isCustomReport = false) => {
    setWorkspaces(currentWorkspaces => {
      const newWorkspaces = [...currentWorkspaces];
      const currentWorkspaceIndex = newWorkspaces.findIndex(w => w.workspace_id === workspaceId);
      const updatedWorkspace = newWorkspaces[currentWorkspaceIndex];
      updatedWorkspace.reports.push({
        report_name: reportName,
        report_id: reportId,
        isCustomModel: isCustomReport
      });
      newWorkspaces[currentWorkspaceIndex] = updatedWorkspace;
      return newWorkspaces;
    });
  };

  const addUserToProviderWorkspace = (workspaceId, user) => {
    setWorkspaces(currentWorkspaces => {
      const newWorkspaces = [...currentWorkspaces];
      const currentWorkspaceIndex = newWorkspaces.findIndex(w => w.workspace_id === workspaceId);
      const updatedWorkspace = newWorkspaces[currentWorkspaceIndex];
      updatedWorkspace.workspace_users.push(user);
      newWorkspaces[currentWorkspaceIndex] = updatedWorkspace;
      return newWorkspaces;
    });
  };

  const addUserToProviderReport = (workspaceId, reportId, user) => {
    setWorkspaces(currentWorkspaces => {
      const newWorkspaces = [...currentWorkspaces];
      const currentWorkspaceIndex = newWorkspaces.findIndex(w => w.workspace_id === workspaceId);
      const updatedWorkspace = newWorkspaces[currentWorkspaceIndex];

      const newReports = [...updatedWorkspace.reports];
      const currentReportIndex = newReports.findIndex(r => r.report_id === reportId);
      const updatedReport = newReports[currentReportIndex];

      updatedReport.report_users = [...(updatedReport.report_users ?? []), user];

      newReports[currentReportIndex] = updatedReport;
      updatedWorkspace.reports = newReports;

      newWorkspaces[currentWorkspaceIndex] = updatedWorkspace;
      return newWorkspaces;
    });
  };

  const deleteUserFromProviderWorkspace = (workspaceId, userId) => {
    setWorkspaces(currentWorkspaces => {
      const newWorkspaces = [...currentWorkspaces];
      const currentWorkspaceIndex = newWorkspaces.findIndex(w => w.workspace_id === workspaceId);
      const updatedWorkspace = newWorkspaces[currentWorkspaceIndex];

      const userToDeleteIndex = updatedWorkspace.workspace_users.findIndex(u => u.user_id === userId);
      updatedWorkspace.workspace_users.splice(userToDeleteIndex, 1);
      newWorkspaces[currentWorkspaceIndex] = updatedWorkspace;
      return newWorkspaces;
    });
  };

  const deleteUserFromProviderReport = (workspaceId, reportId, userId) => {
    setWorkspaces(currentWorkspaces => {
      const newWorkspaces = [...currentWorkspaces];
      const currentWorkspaceIndex = newWorkspaces.findIndex(w => w.workspace_id === workspaceId);
      const updatedWorkspace = newWorkspaces[currentWorkspaceIndex];

      const newReports = [...updatedWorkspace.reports];
      const currentReportIndex = newReports.findIndex(r => r.report_id === reportId);
      const updatedReport = newReports[currentReportIndex];
      const userToDeleteIndex = updatedReport.report_users.findIndex(u => u.user_id === userId);

      console.log({updatedReport, currentReportIndex, updatedWorkspace, reportId});

      updatedReport.report_users.splice(userToDeleteIndex, 1);

      newReports[currentReportIndex] = updatedReport;
      updatedWorkspace.reports = newReports;

      newWorkspaces[currentWorkspaceIndex] = updatedWorkspace;
      return newWorkspaces;
    });
  };

  useEffect(() => {
    setSelectedWorkspace((workspaces || []).find(w => w.workspace_id === selectedWorkspaceId));
  }, [selectedWorkspaceId]);

  const openWorkspaceTabsMenu = event => {
    setWorkspacesTabsMenuAchorEl(event.currentTarget);
  };

  const closeWorkspaceTabsMenu = () => {
    setWorkspacesTabsMenuAchorEl(null);
    setRightClickedWorkspace(null);
  };

  const {_post, _get} = useHttp();
  const {showSnackbar, defaultSnackbarOptions} = useSnackbar();

  const getWorkspaces = async () => {
    const url = API_ENDPOINTS.workspaces.findAll;
    try {
      const {response, responseJson: data} = await _get(url);

      if (response.status === 200) {
        setWorkspaces([DEFAULT_WORKSPACE, ...data]);

        return {
          status: 200,
          success: true,
          data: [DEFAULT_WORKSPACE, ...data]
        };
      }
      // eslint-disable-next-line no-console
      console.error({response, data});
      throw Error(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      setWorkspaces([]);
      return {
        success: false,
        messsage: e.message
      };
    }
  };

  const createWorkspace = async name => {
    const url = API_ENDPOINTS.workspaces.create;
    try {
      const {response, responseJson: data} = await _post(url, {
        workspace_name: name
      });

      if (response.status === 200) {
        closeWorkspaceTabsMenu();

        setWorkspaces([DEFAULT_WORKSPACE, ...data]);

        return {
          status: 200,
          success: true
        };
      }
      // eslint-disable-next-line no-console
      console.error({response, data});
      throw Error(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        success: false,
        messsage: e.message
      };
    }
  };

  const deleteProviderWorkspace = workspace => {
    const associatedReportsIds = workspace.reports?.map(r => r.report_id);
    if (associatedReportsIds) {
      setReports(reportsBeforeWorkspaceDeletion => reportsBeforeWorkspaceDeletion.filter(r => !associatedReportsIds.includes(r.report_id)));
    }
    setWorkspaces(workspacesBeforeDeletion => workspacesBeforeDeletion.filter(w => w.workspace_id !== workspace.workspace_id));
    setSelectedWorkspaceId(DEFAULT_WORKSPACE.workspace_id);
  };

  const deleteWorkspace = async workspaceId => {
    const url = API_ENDPOINTS.workspaces.delete;
    try {
      const {response, responseJson: data} = await _post(url, {
        workspace_id: workspaceId
      });
      const workspaceToDelete = workspaces.find(w => w.workspace_id === workspaceId);

      if (response.status === 200) {
        const workspaceName = workspaces.find(w => w.workspace_id === workspaceId)?.workspace_name;
        showSnackbar(SNACKBAR_ACTIONS.DELETE_WORKSPACE_SUCCESS, defaultSnackbarOptions, {
          workspaceName
        });

        deleteProviderWorkspace(workspaceToDelete);
        return {
          status: 200,
          success: true
        };
      }
      // eslint-disable-next-line no-console
      console.error({response, data});
      throw Error(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        success: false,
        messsage: e.message
      };
    }
  };

  const createReport = async (name, workspaceId) => {
    const url = API_ENDPOINTS.reports.create;
    try {
      const {response, responseJson: data} = await _post(url, {
        report_name: name,
        workspace_id: workspaceId
      });

      if (response.status === 200) {
        showSnackbar(SNACKBAR_ACTIONS.CREATE_REPORT_SUCCESS, defaultSnackbarOptions, {
          reportName: name
        });

        addReportToWorkspace(workspaceId, name, data.report_id);

        return {
          status: 200,
          success: true
        };
      }
      // eslint-disable-next-line no-console
      console.error({response, data});
      throw Error(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        success: false,
        messsage: e.message
      };
    }
  };

  const deleteProviderReport = (workspaceId, reportId) => {
    setWorkspaces(workspacesBeforeReportDeletion => {
      const newWorkspaces = [...workspacesBeforeReportDeletion];
      const deletedReportWorkspaceIndex = newWorkspaces.findIndex(w => w.workspace_id === workspaceId);
      const deletedReportWorkspace = newWorkspaces[deletedReportWorkspaceIndex];
      deletedReportWorkspace.reports = deletedReportWorkspace.reports.filter(r => r.report_id !== reportId);
      newWorkspaces[deletedReportWorkspaceIndex] = deletedReportWorkspace;
      return newWorkspaces;
    });
  };

  const deleteReport = async (workspaceId, reportId) => {
    const url = API_ENDPOINTS.reports.delete;
    try {
      const {response, responseJson: data} = await _post(url, {
        report_id: reportId,
        workspace_id: workspaceId
      });
      deleteProviderReport(workspaceId, reportId);

      if (response.status === 200) {
        await loadReports();
        showSnackbar(data);
        return {
          status: 200,
          success: true
        };
      }
      // eslint-disable-next-line no-console
      console.error({response, data});
      throw Error(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        success: false,
        messsage: e.message
      };
    }
  };

  const importReport = async reportData => {
    const {file, workspaceId, role, isEffectiveIdentityRolesRequired} = reportData;
    setImportReportError('');
    try {
      const b64 = await getFileB64(file);

      const url = API_ENDPOINTS.reports.import;
      const {response, responseJson: data} = await _post(url, {
        workspace_id: workspaceId,
        filename: file.name,
        file_b64: b64.split('base64,')[1],
        isEffectiveIdentityRolesRequired,
        ...(Boolean(isEffectiveIdentityRolesRequired) && {role})
      });

      if (response.status !== 200) {
        throw Error(data?.message || data);
      }
      await loadReports();
      showSnackbar(SNACKBAR_ACTIONS.IMPORT_REPORT_SUCCESS);
      addReportToWorkspace(workspaceId, data.report_name, data.report_id, true);

      return {
        status: 200,
        success: true
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      setImportReportError(e.message);
      return {
        message: e.message,
        success: false
      };
    }
  };

  const exportReport = async (workspaceId, reportId) => {
    try {
      const url = API_ENDPOINTS.reports.export;
      const {response, responseJson: data} = await _post(url, {
        workspace_id: workspaceId,
        report_id: reportId
      });

      if (response.status !== 200) {
        throw Error(data?.message || data);
      }
      return {
        status: 200,
        data,
        success: true
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      setImportReportError(e.message);
      return {
        message: e.message,
        success: false
      };
    }
  };

  // TODO DRY factorize with deleteUserFromWorkspaceOrReport
  const addUserToReportOrWorkspace = async ({isWorkspaceAdding, email, workspaceId, reportId}) => {
    try {
      const url = API_ENDPOINTS.workspaces.addUser;

      const {response, responseJson: data} = await _post(url, {
        workspace_id: workspaceId,
        level: isWorkspaceAdding ? USERS_AUTHORIZATION_LEVELS.workspace : USERS_AUTHORIZATION_LEVELS.report,
        report_id: isWorkspaceAdding ? '00000000-0000-0000-0000-000000000000' : reportId,
        guest_usermail: email
      });

      const successHttpCodes = [200, 402];
      if (!successHttpCodes.includes(response.status)) {
        throw Error(data?.message || data);
      }

      if (response.status === 200) {
        showSnackbar(SNACKBAR_ACTIONS.ADD_USER_SUCCESS, defaultSnackbarOptions, {newUserUsername: data.givenName});
      }

      return {
        status: response.status,
        data,
        success: true
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        message: e.message,
        success: false
      };
    }
  };

  const addUserToWorkspace = async (email, workspaceId) => {
    const res = await addUserToReportOrWorkspace({isWorkspaceAdding: true, email, workspaceId});
    if (res.success && res.status === 200) {
      const user = res.data;
      const formattedUser = {
        username: user.givenName,
        user_id: user.id,
        email: user.mail
      };
      addUserToProviderWorkspace(workspaceId, formattedUser);
    }

    console.log({res});

    return res;
  };

  const addUserToReport = async (email, workspaceId, reportId) => {
    const res = await addUserToReportOrWorkspace({isWorkspaceAdding: false, email, workspaceId, reportId});
    if (res.success) {
      const user = res.data;
      const formattedUser = {
        username: user.givenName,
        user_id: user.id,
        email: user.mail
      };
      addUserToProviderReport(workspaceId, reportId, formattedUser);
    }

    return res;
  };

  // TODO DRY factorize with addUserForWorkspaceOrReport
  const deleteUserFromWorkspaceOrReport = async ({isWorkspaceDeleting, userId, workspaceId, reportId}) => {
    try {
      const url = API_ENDPOINTS.workspaces.deleteUser;

      const {response, responseJson: data} = await _post(url, {
        workspace_id: workspaceId,
        level: isWorkspaceDeleting ? USERS_AUTHORIZATION_LEVELS.workspace : USERS_AUTHORIZATION_LEVELS.report,
        report_id: isWorkspaceDeleting ? '00000000-0000-0000-0000-000000000000' : reportId,
        delete_user_id: userId
      });

      if (response.status !== 200) {
        throw Error(data?.message || data);
      }

      showSnackbar(SNACKBAR_ACTIONS.DELETE_USER_SUCCESS);
      return {
        status: 200,
        data,
        success: true
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        message: e.message,
        success: false
      };
    }
  };

  const deleteUserFromWorkspace = async (userId, workspaceId, isUserDeletingHimSelf) => {
    const res = await deleteUserFromWorkspaceOrReport({isWorkspaceDeleting: true, userId, workspaceId});

    if (res.success) {
      // if self-deletion, delete whole workspace
      if (isUserDeletingHimSelf) {
        setSelectedWorkspaceId(DEFAULT_WORKSPACE.workspace_id);
        setSelectedWorkspace(DEFAULT_WORKSPACE);
        deleteWorkspaceFromProvider(workspaceId);
        // if deleting another user, delete user from workspace users list
      } else {
        deleteUserFromProviderWorkspace(workspaceId, userId);
      }
      closeWorkspaceTabsMenu();
    }

    return res;
  };

  const deleteUserFromReport = async ({userId, workspaceId, reportId, isUserDeletingHimSelf}) => {
    const res = await deleteUserFromWorkspaceOrReport({isWorkspaceDeleting: false, userId, workspaceId, reportId});

    if (res.success) {
      if (isUserDeletingHimSelf) {
        const workspace = workspaces.find(w => w.workspace_id === workspaceId);
        // eslint-disable-next-line no-use-before-define
        const numberOfReportsOwnedInWorkspace = getCountReportsUserOwnsInWorkspace(userId, workspace);

        if (numberOfReportsOwnedInWorkspace === 1) {
          deleteProviderWorkspace(workspace);
        } else {
          deleteProviderReport(workspaceId, reportId);
        }
      } else {
        deleteUserFromProviderReport(workspaceId, reportId, userId);
      }
    }
    return res;
  };

  const openCreateWorkspaceModal = () => {
    setIsExistingWorkspaceEdition(false);
    setCreateOrUpdateWorkspaceModalOpen(true);
    closeWorkspaceTabsMenu();
  };

  const findReportInWorkspaces = reportId => {
    const workspaceContainingReport = workspaces.filter(workspace => Array.isArray(workspace.reports)).find(workspace => workspace.reports.some(report => report.report_id === reportId));

    console.log({workspaceContainingReport, workspaces});
    if (!workspaceContainingReport) {
      return null;
    }

    const report = workspaceContainingReport.reports.find(r => r.report_id === reportId);
    console.log({report, workspaceContainingReport});
    return {
      report,
      workspace: workspaceContainingReport
    };
  };

  const isUserInWorkspace = (userId, workspace) => {
    if (!userId || !workspace) return false;

    const user = workspace.workspace_users?.find(u => u.user_id.toLowerCase() === userId.toLowerCase());

    return !!user;
  };

  const isUserOwnerOfWorkspace = (userId, workspace) => {
    const user = workspace.workspace_users?.find(u => u.user_id.toLowerCase() === userId.toLowerCase());
    if (!user) {
      return false;
    }

    return user?.is_owner;
  };

  const isUserOwnerOfReport = (userId, reportId) => {
    const {report, workspace} = findReportInWorkspaces(reportId);

    console.log({userId, report, workspace});

    // Business rule: user owner at workspace level is automatically owner at report level
    const userOwnerOfWorkspace = isUserOwnerOfWorkspace(userId, workspace);
    if (userOwnerOfWorkspace) {
      return true;
    }

    const user = report.report_users?.find(u => u.user_id.toLowerCase() === userId.toLowerCase());
    return user.is_owner;
  };

  const getCountReportsUserOwnsInWorkspace = (userId, workspace) => {
    return workspace.reports.reduce((accumulator, report) => {
      const isUserOwner = isUserOwnerOfReport(userId, report.report_id);
      console.log({isUserOwner});
      return accumulator + (isUserOwner ? 1 : 0);
    }, 0);
  };

  const memoizedValues = {
    getWorkspaces,
    workspaces,
    numberOfWorkspaces: workspaces.length,
    createWorkspace,
    deleteWorkspace,
    selectedWorkspace,
    selectedWorkspaceId,
    setSelectedWorkspaceId,
    createReport,
    deleteReport,
    createReportModalOpen,
    setCreateReportModalOpen,
    isDefaultWorkspace: selectedWorkspaceId === DEFAULT_WORKSPACE.workspace_id,
    workspacesTabsMenuAchorEl,
    openWorkspaceTabsMenu,
    closeWorkspaceTabsMenu,
    importReport,
    importReportError,
    setImportReportError,
    exportReport,
    isCustomReportCreation,
    setIsCustomReportCreation,
    setWorkspacesTabsMenuAchorEl,
    anchorElReportTabsMenu,
    setAnchorElReportTabsMenu,
    rightClickedReportTab,
    setRightClickedReportTab,
    openCreateWorkspaceModal,
    isExistingWorkspaceEdition,
    setIsExistingWorkspaceEdition,
    createOrUpdateWorkspaceModalOpen,
    setCreateOrUpdateWorkspaceModalOpen,
    deleteWorkspaceOrReportModalOpen,
    rightClickedWorkspace,
    setRightClickedWorkspace,
    setDeleteWorkspaceOrReportModalOpen,
    isWorkspaceUsersModalOpen,
    setIsWorkspaceUsersModalOpen,
    addUserToWorkspace,
    addUserToReport,
    deleteUserFromWorkspace,
    deleteUserFromReport,
    isUserOwnerOfReport,
    isUserOwnerOfWorkspace,
    isUserInWorkspace
  };

  const useMemoDeps = Object.values(memoizedValues).map(value => value);

  const value = useMemo(() => memoizedValues, useMemoDeps);

  return <WorkspacesContext.Provider value={value}>{children}</WorkspacesContext.Provider>;
};

WorkspacesProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default WorkspacesProvider;
