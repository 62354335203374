import {useEffect, useState} from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {Typography, Box, Grid, TextField, DialogContent, IconButton} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';

import useAuth from '../../hooks/providers/useAuth';
import useProjects from '../../hooks/providers/useProjects';
import Button from '../form/buttons/Button';
import ErrorMessage from '../form/ErrorMessage';
import BaseModal from '../modal/BaseModal';
import Spinner from '../spinner/Spinner';

const InputLabel = muiStyled(Typography)(({theme}) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  fontWeight: 'bold',
  fontSize: 12,
  textTransform: 'uppercase'
}));

const ContactFormBackdrop = () => {
  const projectsContext = useProjects();
  const {isContactFormOpen: open, setIsContactFormOpen} = useAuth();

  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setError('');
    const result = await projectsContext.sendContactMessage(subject, message);
    setIsSubmitting(false);
    if (result.status === 200) {
      setIsContactFormOpen(false);
    } else {
      setError(result.message);
    }
  };

  useEffect(() => {
    setSubject('');
    setMessage('');
    setError('');
  }, [open]);

  return (
    <BaseModal hasCloseIcon onClose={() => setIsContactFormOpen(false)} open={open} title="Contact">
      <DialogContent px={2}>
        <InputLabel>Sujet</InputLabel>
        <TextField
          sx={{mb: 2}}
          size="small"
          value={subject}
          label="Sujet"
          color="primary"
          placeholder="Objet de la demande"
          fullWidth
          onChange={event => {
            setSubject(event.target.value);
          }}
        />
        <InputLabel>Message</InputLabel>
        <TextField
          sx={{mb: 4}}
          multiline
          rows={10}
          value={message}
          label="Message"
          color="primary"
          placeholder="Veuillez indiquer ici le motif de votre message ..."
          fullWidth
          onChange={event => {
            setMessage(event.target.value);
          }}
        />
        <Box mt={isSubmitting ? 3 : 0}>
          <Spinner text="Votre message est en cours d'envoi" isLoading={isSubmitting} />
        </Box>
        <Grid container justifyContent="center" pb={2}>
          <Grid item xs={12} sm={6}>
            <Button disabled={isSubmitting || !message || !subject} size="large" fullWidth variant="contained" color="secondary" onClick={handleSubmit}>
              Envoyer
            </Button>
          </Grid>
        </Grid>
        {error && <ErrorMessage message={error} />}
      </DialogContent>
    </BaseModal>
  );
};

export default ContactFormBackdrop;
