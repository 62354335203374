import {MAXIMUM_TRANSCRIPT_AUDIO_FILE_SIZE, SNACKBAR_ACTIONS} from '../const';

const {
  ADD_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  TECHNICAL_ERROR,
  SEND_CONTACT_MESSAGE_SUCCESS,
  CREATE_DEFI_PREVI_BUDGET_SUCCESS,
  IMPORT_PROJECT_FILES_SUCCESS,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_SUCCESS_WITH_NAME,
  UPDATE_PROJECT_SUCCESS,
  REFRESH_PROJECT_LOADING,
  REFRESH_PROJECT_SUCCESS,
  PROMOTE_USER_SUCCESS,
  DEMOTE_USER_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_PARTIAL,
  UPDATE_PROFILE_ERROR,
  SUBSCRIBE_LOADING,
  UPLOAD_ERROR_DOCUMENT_TYPE,
  PENNYLANE_DATA_INTEGRATION_IN_PROGRESS,
  PENNYLANE_DATA_INTEGRATION_COMPLETED,
  PENNYLANE_DATA_INTEGRATION_ERROR,
  CREATE_WORKSPACE_SUCCESS,
  CREATE_REPORT_SUCCESS,
  DELETE_WORKSPACE_SUCCESS,
  REPORT_SUCCESSFULLY_SAVED,
  SEND_CHATBOT_MESSAGE_ERROR,
  SUBSCRIBE_ERROR_EMAIL_ALREADY_TAKEN,
  DELETE_DEFI_PREVI_BUDGET_SUCCESS,
  UPDATE_SPREADSHEET_DATA_IN_PROGRESS,
  UPDATE_SPREADSHEET_DATA_SUCCESS,
  UPDATE_SPREADSHEET_DATA_ERROR,
  UPLOAD_ERROR_TOO_MANY_FILES,
  IMPORT_REPORT_SUCCESS,
  EXPORT_REPORT_IN_PROGRESS,
  EXPORT_REPORT_SUCCESS,
  UPDATE_REPORT_SUMMARY,
  UPDATE_REPORT_SUMMARY_SUCCESS,
  SEND_DOCUMENT_REQUEST_SUCCESS,
  USER_HAS_DECLINED_MICROPHONE_PERMISSION,
  TRANSCRIPTION_AUDIO_FILE_EXCEEDS_MAXIMUM_SIZE,
  TRANSCRIPTION_AUDIO_FILE_WILL_SOON_EXCEED_MAXIMUM_SIZE_WARNING,
  INVALID_TRANSCRIPTION
} = SNACKBAR_ACTIONS;

// eslint-disable-next-line complexity
export const getSnackbarMessage = (actionOrMessage, data) => {
  const {
    createdProjectName,
    firstFiscalMonthHasChanged,
    projectRefreshing,
    promotedUserEmail,
    demotedUserEmail,
    newUserUsername,
    workspaceName,
    reportName,
    acceptedExtensions,
    exportedReportName,
    currentFileSize
  } = data;

  switch (actionOrMessage) {
    case PENNYLANE_DATA_INTEGRATION_IN_PROGRESS:
      return `Vos données sont en cours d'intégration.`;
    case PENNYLANE_DATA_INTEGRATION_COMPLETED:
      return `L'intégration de vos données est terminée !`;
    case PENNYLANE_DATA_INTEGRATION_ERROR:
      return `Une erreur est survenue au cours de l'intégration des données.`;
    case ADD_USER_SUCCESS:
      return `Dossier partagé avec ${newUserUsername} !`;
    case DELETE_USER_SUCCESS:
      return 'Utilisateur supprimé avec succès !';
    case TECHNICAL_ERROR:
      return 'Une erreur est survenue. Nos équipes sont prévenues et le problème est en cours de résolution.';
    case SEND_CONTACT_MESSAGE_SUCCESS:
      return 'Votre message a bien été envoyé !';
    case CREATE_DEFI_PREVI_BUDGET_SUCCESS:
      return 'Budget créé avec succès !';
    case DELETE_DEFI_PREVI_BUDGET_SUCCESS:
      return 'Budget supprimé avec succès !';
    case IMPORT_PROJECT_FILES_SUCCESS:
      return 'Nous avons bien reçu vos fichiers. Vous pouvez maintenant consulter votre tableau de bord.';
    case CREATE_PROJECT_SUCCESS:
      return 'Dossier créé avec succès !';
    case CREATE_PROJECT_SUCCESS_WITH_NAME:
      return `Dossier ${createdProjectName} créé avec succès`;
    case UPDATE_PROJECT_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const fiscalMonthHasChangedText = firstFiscalMonthHasChanged ? `La modification sera effective dans votre rapport d'ici quelques minutes` : '';
      return `Les paramètres du projet ont été mis à jour ! ${fiscalMonthHasChangedText}`;
    case REFRESH_PROJECT_LOADING:
      return `${projectRefreshing} en cours d'actualisation.`;
    case REFRESH_PROJECT_SUCCESS:
      return `Actualisation terminée.`;
    case PROMOTE_USER_SUCCESS:
      return `${promotedUserEmail} est désormais propriétaire !`;
    case DEMOTE_USER_SUCCESS:
      return `${demotedUserEmail} est désormais invité !`;
    case UPDATE_PROFILE_SUCCESS:
      return 'Votre profil a bien été mis à jour !';
    case UPDATE_PROFILE_PARTIAL:
      return "Votre profil a été partiellement mis à jour. Le numéro de téléphone n'a pas pu être modifié...";
    case UPDATE_PROFILE_ERROR:
      return "Votre profil n'a pas pu être mis à jour. Veuillez réessayer plus tard...";
    case UPLOAD_ERROR_DOCUMENT_TYPE:
      return `Les fichiers doivent être au format ${acceptedExtensions}`;
    case UPLOAD_ERROR_TOO_MANY_FILES:
      return 'Vous avez sélectionné trop de fichiers.';
    case SUBSCRIBE_LOADING:
      return `Veuillez patienter pendant que nous vous donnons accès à l'application.`;
    case CREATE_WORKSPACE_SUCCESS:
      return `L'espace de travail " ${workspaceName} " a été créé avec succès !`;
    case DELETE_WORKSPACE_SUCCESS:
      return `L'espace de travail " ${workspaceName} " a été supprimé !`;
    case CREATE_REPORT_SUCCESS:
      return `Le rapport " ${reportName} " a été créé avec succès !`;
    case REPORT_SUCCESSFULLY_SAVED:
      return 'Votre rapport a bien été sauvegardé !';
    case SEND_CHATBOT_MESSAGE_ERROR:
      return "Une erreur est survenue lors de l'envoi de votre message";
    case SUBSCRIBE_ERROR_EMAIL_ALREADY_TAKEN:
      return 'Cette adresse mail est déjà utilisée. Si vous avez oublié votre mot de passe, réinitialisez-le';
    case UPDATE_SPREADSHEET_DATA_IN_PROGRESS:
      return 'Mise à jour en cours';
    case UPDATE_SPREADSHEET_DATA_SUCCESS:
      return 'Rapport actualisé';
    case IMPORT_REPORT_SUCCESS:
      return 'Le rapport a été importé avec succès';
    case UPDATE_SPREADSHEET_DATA_ERROR:
      return 'Erreur lors de la mise à jour des données';
    case EXPORT_REPORT_IN_PROGRESS:
      return `Téléchargement du rapport '${exportedReportName}' en cours`;
    case EXPORT_REPORT_SUCCESS:
      return `Rapport '${exportedReportName}' téléchargé avec succès`;
    case UPDATE_REPORT_SUMMARY:
      return "Veuillez patienter pendant la mise à jour du résumé et la génération de l'audio";
    case UPDATE_REPORT_SUMMARY_SUCCESS:
      return 'Résumé mis à jour avec succès';
    case SEND_DOCUMENT_REQUEST_SUCCESS:
      return 'Votre demande a été envoyée';
    case TRANSCRIPTION_AUDIO_FILE_EXCEEDS_MAXIMUM_SIZE:
      return `Le fichier audio généré dépasse la taille maximale autorisé (${MAXIMUM_TRANSCRIPT_AUDIO_FILE_SIZE} Mo).`;
    case TRANSCRIPTION_AUDIO_FILE_WILL_SOON_EXCEED_MAXIMUM_SIZE_WARNING:
      return `Taille maximale de l'audio bientôt atteinte ( ${currentFileSize} / ${MAXIMUM_TRANSCRIPT_AUDIO_FILE_SIZE} Mo). Veuillez terminer votre enregistrement.`;
    case USER_HAS_DECLINED_MICROPHONE_PERMISSION:
      return "Vous devez autoriser l'accès au microphone pour pouvoir enregistrer";
    case INVALID_TRANSCRIPTION:
      return 'Erreur lors de l’envoi de vos données, veuillez réessayer';
    // Sometimes, we'll want to display a message from the API in the snackbar.
    // As we cannot hardcode the message here, we will directly pass it to getSnackbarMessage instead of passing an existing SNACKBAR_ACTION
    default:
      return actionOrMessage;
  }
};
