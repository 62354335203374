const TRANSCRIPT_WORKING_DAY_SPREADSHEET_HEADER_ROW = {
  rowId: 'header',
  height: 40,
  cells: [
    {type: 'header', text: 'DATE', className: 'header-cell'},
    {type: 'header', text: 'DOSSIER', className: 'header-cell'},
    {type: 'header', text: 'ACTION', className: 'header-cell'},
    {type: 'header', text: 'MISSION', className: 'header-cell'},
    {type: 'header', text: 'TÂCHE', className: 'header-cell'},
    {type: 'header', text: 'TEMPS EN HEURES', className: 'header-cell'},
    {type: 'header', text: ' ', className: 'header-cell'}
  ]
};

const TRANSCRIPT_WORKING_DAY_COLUMNS = {
  date: {name: 'date', width: 100},
  folder: {name: 'folder', width: 250},
  action: {name: 'action', width: 300},
  mission: {name: 'mission', width: 125},
  task: {name: 'task', width: 300},
  duration: {name: 'duration', width: 175},
  delete: {name: 'delete', width: 50}
};

const calculateTotalWidthExcludingAction = () => {
  return Object.entries(TRANSCRIPT_WORKING_DAY_COLUMNS)
    .filter(([key, _]) => key !== 'action')
    .reduce((total, [_, column]) => total + column.width, 0);
};

const getTranscriptWorkingDaySpreadsheetColumns = dialogWidth => {
  const widthTaken = calculateTotalWidthExcludingAction();
  const widthLeft = dialogWidth - widthTaken;

  console.log({dialogWidth, widthTaken, widthLeft});
  return [
    {
      columnId: TRANSCRIPT_WORKING_DAY_COLUMNS.date.name,
      width: TRANSCRIPT_WORKING_DAY_COLUMNS.date.width
    },
    {
      columnId: TRANSCRIPT_WORKING_DAY_COLUMNS.folder.name,
      width: TRANSCRIPT_WORKING_DAY_COLUMNS.folder.width
    },
    {
      columnId: TRANSCRIPT_WORKING_DAY_COLUMNS.action.name,
      width: widthLeft < TRANSCRIPT_WORKING_DAY_COLUMNS.action.width ? TRANSCRIPT_WORKING_DAY_COLUMNS.action.width : widthLeft
    },
    {
      columnId: TRANSCRIPT_WORKING_DAY_COLUMNS.mission.name,
      width: TRANSCRIPT_WORKING_DAY_COLUMNS.mission.width
    },
    {
      columnId: TRANSCRIPT_WORKING_DAY_COLUMNS.task.name,
      width: TRANSCRIPT_WORKING_DAY_COLUMNS.task.width
    },
    {
      columnId: TRANSCRIPT_WORKING_DAY_COLUMNS.duration.name,
      width: TRANSCRIPT_WORKING_DAY_COLUMNS.duration.width
    },
    {
      columnId: TRANSCRIPT_WORKING_DAY_COLUMNS.delete.name,
      width: TRANSCRIPT_WORKING_DAY_COLUMNS.delete.width
    }
  ];
};

export {TRANSCRIPT_WORKING_DAY_SPREADSHEET_HEADER_ROW, TRANSCRIPT_WORKING_DAY_COLUMNS, getTranscriptWorkingDaySpreadsheetColumns};
