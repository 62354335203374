import {useRef} from 'react';

import Box from '@mui/material/Box';
import {useLongPress} from '@uidotdev/usehooks';
import {func} from 'prop-types';

import useBreakpoint from '../../hooks/dom/useBreakpoint';
import useProjects from '../../hooks/providers/useProjects';
import useWorkspaces from '../../hooks/providers/useWorkspaces';
import {isResponsive} from '../../utils';
import StyledTab from './StyledTab';
import StyledTabs from './StyledTabs';
import TabLabel from './TabLabel';
import TabSeparator from './TabSeparator';

const CustomReportTabs = ({handleChange}) => {
  const {selectedWorkspace, setAnchorElReportTabsMenu, setRightClickedReportTab, isDefaultWorkspace} = useWorkspaces();
  const {selectedReportTab} = useProjects();

  const currentBreakpoint = useBreakpoint();
  const responsive = isResponsive(currentBreakpoint);

  const anchorRef = useRef(null);
  const longPress = useLongPress(
    () => {
      const target = anchorRef.current;
      setAnchorElReportTabsMenu(target);
    },
    {
      onStart: event => {
        anchorRef.current = event.currentTarget;
      },
      threshold: 400
    }
  );

  const handleTabRightClick = (e, report) => {
    if (isDefaultWorkspace || report.is_notice_report) return;

    e.preventDefault();
    setAnchorElReportTabsMenu(e.currentTarget);
    setRightClickedReportTab(report);
  };

  const getCustomReportStyledTab = r => {
    const tabKey = r.report_id;
    const longPressAttributes = responsive ? longPress : {};

    // onContextMenu and longPress accomplish the same role, but onContextMenu event does no work on iOS
    // see react issue : https://github.com/facebook/react/issues/17596
    const Tab = (
      <StyledTab
        onContextMenu={e => handleTabRightClick(e, r)}
        isReportTab
        key={tabKey}
        label={r.is_notice_report ? r.report_name : <TabLabel onIconClick={e => handleTabRightClick(e, r)} label={r.report_name} isActive={selectedReportTab === tabKey} />}
        value={r.report_id}
        {...longPressAttributes}
      />
    );

    return Tab;
  };

  const getCustomReportTabSeparators = (r, index) => {
    const separatorKey = `${r.report_id}-separator`;
    const secondSeparatorKey = `${r.report_id}-second-separator`;

    const separators = [];
    const isLastItem = index === selectedWorkspace.reports.length - 1;
    if (!isLastItem) {
      separators.push(<TabSeparator key={separatorKey} />);
      // Double separator for Notice report
      if (r.is_notice_report) {
        separators.push(
          <Box key={secondSeparatorKey} height={16} ml={0.5}>
            <TabSeparator />
          </Box>
        );
      }
    }
    return separators;
  };

  if (!selectedWorkspace?.reports) return null;

  // Business need: we have to "extract" Notice report from all reports list.
  // We want to display this specific report at the first position, and potentially apply specific style to it
  const alphabeticallySortedReports = selectedWorkspace.reports?.sort((a, b) => a.report_name.toLowerCase().localeCompare(b.report_name.toLowerCase())).filter(r => !r.is_notice_report);
  const noticeReport = selectedWorkspace.reports?.find(r => r.is_notice_report);
  if (noticeReport) {
    alphabeticallySortedReports.unshift(noticeReport);
  }

  // We build MUI tabs this way because using React Fragment inside StyledTabs below
  // Makes the component dysfunctional and triggers a MUI error
  const tabs = [];

  // eslint-disable-next-line no-console
  console.log(
    '%c INFO : If you encounter MUI Errors such as "React does not recognize the `fullWidth`, `selectionAllowFocus` prop on a DOM element", they are caused by the following code lines. As StyledTabs (MUI <Tabs>) does not allow other components than a MUI <Tab> as children (Or, more precisely, it is spreading props to its children, so any other than a <Tab> cannot accept such props). However, this solution was the simplest to display separators between each StyledTab',
    'background: #222; color: #bada55'
  );
  console.log({alphabeticallySortedReports});

  alphabeticallySortedReports.forEach((r, index) => {
    const tab = getCustomReportStyledTab(r);
    const separators = getCustomReportTabSeparators(r, index);
    tabs.push(tab);
    separators.forEach(sep => tabs.push(sep));
  });

  return (
    <StyledTabs variant="scrollable" scrollButtons={false} isReportTabs onChange={handleChange} aria-label="Sélecteur de schema">
      {tabs}
    </StyledTabs>
  );
};

CustomReportTabs.propTypes = {
  handleChange: func.isRequired
};

export default CustomReportTabs;
