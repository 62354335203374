const hasSpreadsheetDataChanged = (baseData, currentData, budget = null) => {
  let hasChanged = false;
  const changes = [];

  if (budget) {
    // eslint-disable-next-line no-param-reassign
    baseData = baseData.filter(entry => entry.budget_name === budget);
  }

  // Some lines have been removed
  if (currentData.length < baseData.length) {
    return true;
  }

  // We check for every spreadsheet line if its content has changed
  currentData.forEach(entry => {
    const baseDataEntry = baseData.find(e => e.line === entry.line && (budget ? e.budget_name === budget : true));
    if (JSON.stringify(entry) !== JSON.stringify(baseDataEntry)) {
      changes.push(entry);
      hasChanged = true;
    }
  });

  return hasChanged;
};

const addContextMenuOption = (currentOptions, id, label, handler) => {
  const newOption = {
    id,
    label,
    handler
  };
  return [...currentOptions, newOption];
};

export * from './forecast-spreadsheet';
export * from './budget-spreadsheet';
export * from './send-document-request-spreadsheet';
export * from './transcript-working-day-spreadsheet';

export {hasSpreadsheetDataChanged, addContextMenuOption};
