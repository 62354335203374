import {useEffect, useState} from 'react';

import AddFolderIcon from '@mui/icons-material/CreateNewFolderOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForeverOutlined';
import MuiTabPanel from '@mui/lab/TabPanel';
import {List, Box, Grid, Typography} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import Button from '../components/form/buttons/Button';
import ExpiredSubscriptionAlert from '../components/project/alerts/ExpiredSubscriptionAlert';
import ExpiredTrialAlert from '../components/project/alerts/ExpiredTrialAlert';
import InsufficientSubscriptionAlert from '../components/project/alerts/InsufficientSubscriptionAlert';
import CreateProjectModal from '../components/project/create/CreateProjectModal';
import DeleteProjectModal from '../components/project/DeleteProjectModal';
import MaximumNumberOfProjectsModal from '../components/project/MaximumNumberOfProjectsModal';
import ProjectToolBar from '../components/project/ProjectToolBar';
import SeeCustomReportButton from '../components/project/SeeCustomReportButton';
import ActualizeDataDatePicker from '../components/project/single-project-item/actualize-data-button/ActualizeDataDatePicker';
import Project from '../components/project/single-project-item/Project';
import UpdateProjectModal from '../components/project/UpdateProjectModal';
import ManageProjectUsersModal from '../components/project/users/ManageProjectUsersModal';
import Spinner from '../components/spinner/Spinner';
import ReportTabs from '../components/tabs/ReportTabs';
import WorkspacesTabs from '../components/tabs/WorkspacesTabs';
import ManageWorkspaceOrReportUsersModal from '../components/workspaces/users/ManageWorkspaceOrReportUsersModal';
import {BREAKPOINTS, CONNECTORS, DEFAULT_PROJECTS_SCHEMAS, PROJECTS_VIEW_TYPES} from '../const';
import useBreakpoint from '../hooks/dom/useBreakpoint';
import useAuth from '../hooks/providers/useAuth';
import useLoading from '../hooks/providers/useLoading';
import usePayment from '../hooks/providers/usePayment';
import useProjects from '../hooks/providers/useProjects';
import useReport from '../hooks/providers/useReport';
import useTiimeProjects from '../hooks/providers/useTiimeProjects';
import useWorkspaces from '../hooks/providers/useWorkspaces';

const PageContainer = muiStyled(Box)(({theme}) => ({
  maxWidth: 1200,
  minHeight: 'calc(100vh - 20vh)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxSizing: 'border-box',
  background: 'white',
  boxShadow: '0 0 10px #00000029',
  borderRadius: 10,
  opacity: 1,
  [theme.breakpoints.up('xs')]: {
    margin: '12px 6px'
  },
  [theme.breakpoints.up('sm')]: {
    margin: '24px 12px'
  },
  [theme.breakpoints.up('md')]: {
    margin: '48px 16px'
  },
  [theme.breakpoints.up('lg')]: {
    margin: '48px auto'
  }
}));

const ContentContainer = muiStyled(Box)(({theme}) => ({
  // maxWidth: '100%',
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    padding: '0 10px',
    margin: '0 6px'
  },
  [theme.breakpoints.up('sm')]: {
    padding: '0 24px',
    margin: '0 12px'
  },
  [theme.breakpoints.up('md')]: {
    padding: '0 32px',
    margin: '0 16px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0 48px',
    margin: '0 16px'
  }
}));

const TabContainer = styled.div`
  margin-top: 32px;

  h2 {
    text-align: center;
    font: normal normal 300 26px/40px SoehneBreitKraftig;
    opacity: 0.65;
  }

  h3 {
    text-align: center;
    font: normal normal bold 46px/80px SoehneBreitKraftig;
    opacity: 1;
  }
`;

const CenteredContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SingleProjectContainer = muiStyled(Grid)(({theme}) => ({
  display: 'flex',
  flex: 1,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    minWidth: '100% '
  }
}));

const TabPanel = muiStyled(MuiTabPanel)(({theme}) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1)
  }
}));

const ComingSoonTabPanel = () => (
  <TabContainer>
    <h2>En cours de développement</h2>
    <h3>Disponible Prochainement!</h3>
    <CenteredContainer>
      <Button disabled size="large" variant="contained" color="secondary" startIcon={<AddFolderIcon />}>
        Créer un nouveau projet
      </Button>
    </CenteredContainer>
  </TabContainer>
);

// eslint-disable-next-line react/prop-types
const NoProjectsCreatedContent = ({label}) => (
  <TabContainer>
    <h2>Bienvenue sur votre tableau de bord {label}</h2>
    <h3>Créez votre premier projet !</h3>
  </TabContainer>
);

const NoProjectsFoundContent = () => (
  <TabContainer>
    <Typography variant="h2">Aucun projet ne correspond à vos critères de recherche ...</Typography>
  </TabContainer>
);

// eslint-disable-next-line complexity
const Folders = () => {
  const auth = useAuth();
  const paymentContext = usePayment();

  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);
  const [maximumNumberOfProjectsReachedModalOpen, setMaximumNumberOfProjectsReachedModalOpen] = useState(false);
  const [isDeletingProjectsLoading, setIsDeletingProjectsLoading] = useState(false);
  const [searchedProjectName, setSearchedProjectName] = useState('');

  const location = useLocation();
  const projectsContext = useProjects();
  const {tiimeCompanies} = useTiimeProjects();
  const {selectedReportTab, setConnector, closeProjectMenu} = projectsContext;
  const {selectedWorkspace, isDefaultWorkspace, getWorkspaces, rightClickedWorkspace, rightClickedReportTab} = useWorkspaces();
  const breakpoint = useBreakpoint();
  const {setCompletedApiCalls} = useLoading();
  const projectsLoaded = projectsContext?.projects?.length > 0 && !projectsContext.isLoading;
  const userHasEndedTrialAndIsOwnerOfProjects = paymentContext.numberOfProjectsOwnedByUser > 0 && paymentContext.maxNumberOfProjects === 0;

  useEffect(() => {
    projectsContext.setDefaultDefiReportTab();
  }, []);

  // This hook automatically opens project creation modal if location.state.isTiimeProjectCreationModalOpen
  // This is useful because when user logs to Tiime oauth, he leaves the site. We want to redirect him to the initial page (folders)
  // and keep the modal open + tiime connector selected, to improve UX.
  useEffect(() => {
    const shouldOpenProjectCreationModal = location.state?.isTiimeProjectCreationModalOpen;
    if (shouldOpenProjectCreationModal && tiimeCompanies.length > 0) {
      setCreateProjectModalOpen(true);
      setConnector(CONNECTORS.tiime);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (auth?.user?.tokenAad && projectsContext.shouldFetchProjects) {
        if (auth.isNewUser) {
          await projectsContext.fetchProjects();
        } else {
          await Promise.all([getWorkspaces(), projectsContext.fetchProjects()]);
        }
        setCompletedApiCalls(prevCompleted => prevCompleted + 2);
        projectsContext.setShouldFetchProjects(false);
      }
    })();
  }, [auth?.user?.tokenAad, projectsContext.shouldFetchProjects]);

  // This hook aims to fetch stripe products list
  // Even if products are not used on this page, it will reduce loading time on /profile and /abonnement pages when user navigates there
  useEffect(() => {
    (async () => {
      const {getProductsList, products, productsLoading} = paymentContext;
      if (products?.length < 1 && !productsLoading) {
        await getProductsList();
      }
    })();
  }, [auth?.user?.tokenAad]);

  // This hook tracks window size. Its goal is to automatically set projects list view type to CARD
  // When screen becomes too small to display projects as a list
  useEffect(() => {
    if (breakpoint === BREAKPOINTS.xs) {
      projectsContext.setViewType(PROJECTS_VIEW_TYPES.card);
    }
  }, [breakpoint]);

  const deleteAllProjectsCreatedByUser = async () => {
    setIsDeletingProjectsLoading(true);
    const projectsToDelete = projectsContext.projects?.filter(p => p.is_owner);
    await Promise.all(projectsToDelete.map(p => projectsContext?.deleteProject(p.siren)));
    await projectsContext.fetchProjects();
    setIsDeletingProjectsLoading(false);
  };

  const handleCreateProjectButtonClick = () => {
    const numberOfProjectsLimitReached = paymentContext.numberOfProjectsOwnedByUser >= paymentContext.maxNumberOfProjects;

    if (numberOfProjectsLimitReached) {
      setMaximumNumberOfProjectsReachedModalOpen(true);
    } else {
      setCreateProjectModalOpen(true);
    }
  };

  const isTabVisible = schema => {
    return paymentContext.authorizedModules.includes(schema);
  };

  const getSchemaProjects = () => {
    let schemaProjects = [];
    if (isDefaultWorkspace) {
      schemaProjects = (projectsContext.projects || []).filter(p => p.schema_name === projectsContext.selectedReportTab);
    } else {
      schemaProjects = (projectsContext.projects || []).filter(p => p.report_id === selectedReportTab);
    }

    if (searchedProjectName) {
      schemaProjects = schemaProjects.filter(p => p.project_name.toLowerCase().includes(searchedProjectName.toLowerCase()) || p.siren.includes(searchedProjectName.toLowerCase()));
    }

    return schemaProjects;
  };

  const renderProjects = () => {
    const {viewType} = projectsContext;
    const schemaProjects = getSchemaProjects();

    if (!projectsLoaded) {
      return null;
    }

    return viewType === PROJECTS_VIEW_TYPES.card ? (
      <Grid mt={2} container spacing={2} justifyContent="center" alignItems="stretch">
        {schemaProjects.map((project, index) => (
          <SingleProjectContainer key={index} item xs sm={6} md={4}>
            <Project disabled={userHasEndedTrialAndIsOwnerOfProjects} project={project} />
          </SingleProjectContainer>
        ))}
      </Grid>
    ) : (
      <List dense sx={{mt: 1}}>
        {schemaProjects.map((project, index) => (
          <Project key={index} disabled={userHasEndedTrialAndIsOwnerOfProjects} project={project} />
        ))}
      </List>
    );
  };

  // eslint-disable-next-line complexity
  const getTabContent = tabLabel => {
    const schemaProjects = getSchemaProjects();
    const noProjectsCreated = schemaProjects.length === 0 && !projectsContext.isLoading && !searchedProjectName;
    const noProjectsFound = schemaProjects.length === 0 && !projectsContext.isLoading && searchedProjectName;

    const isNoticeReport = projectsContext.currentReport.is_notice_report;
    const isCustomReport = projectsContext.currentReport.isCustomModel;

    if (isNoticeReport || isCustomReport) {
      return (
        <Grid item xs="auto" sx={{mt: 2}}>
          <SeeCustomReportButton isNoticeReport={isNoticeReport} isCustomReport={isCustomReport} />
        </Grid>
      );
    }

    return (
      <Grid container flex={1} maxWidth="md" sx={{margin: '0 auto'}}>
        <Grid item xs={12}>
          <ExpiredTrialAlert />
          <ExpiredSubscriptionAlert />
          <InsufficientSubscriptionAlert />

          <ProjectToolBar searchedProjectName={searchedProjectName} setSearchedProjectName={setSearchedProjectName} handleCreateProjectButtonClick={handleCreateProjectButtonClick} />

          <Spinner text="Chargement des projets" isLoading={projectsContext.isLoading && !createProjectModalOpen} />

          <ActualizeDataDatePicker />

          {renderProjects()}

          {userHasEndedTrialAndIsOwnerOfProjects && (
            <CenteredContainer>
              <Button size="large" sx={{mt: 2}} disabled={isDeletingProjectsLoading} variant="contained" color="error" startIcon={<DeleteForeverIcon />} onClick={deleteAllProjectsCreatedByUser}>
                Supprimer les projets dont je suis propriétaire
              </Button>
            </CenteredContainer>
          )}

          {noProjectsCreated && <NoProjectsCreatedContent label={tabLabel} />}

          {noProjectsFound && <NoProjectsFoundContent />}
        </Grid>
      </Grid>
    );
  };

  const getSortedReports = () => {
    // Business need: we have to "extract" Notice report from all reports list.
    // We want to display this specific report at the first position, and potentially apply specific style to it
    const alphabeticallySortedReports = selectedWorkspace?.reports?.sort((a, b) => a.report_name.toLowerCase().localeCompare(b.report_name.toLowerCase())).filter(r => !r.is_notice_report) || [];
    const noticeReport = selectedWorkspace?.reports?.find(r => r.is_notice_report);
    if (noticeReport) {
      alphabeticallySortedReports.unshift(noticeReport);
    }
    return alphabeticallySortedReports;
  };

  const alphabeticallySortedReports = getSortedReports();

  return (
    <PageContainer>
      {paymentContext.authorizationsLoading ? (
        <Grid container flex={1}>
          <Spinner text="Chargement des modules" isLoading />
        </Grid>
      ) : (
        <>
          <WorkspacesTabs />
          <ContentContainer>
            <ReportTabs>
              {isDefaultWorkspace ? (
                <>
                  {isTabVisible(DEFAULT_PROJECTS_SCHEMAS.gestion) && (
                    <TabPanel key={DEFAULT_PROJECTS_SCHEMAS.gestion} value={DEFAULT_PROJECTS_SCHEMAS.gestion}>
                      {getTabContent('DeFi Gestion')}
                    </TabPanel>
                  )}
                  {isTabVisible(DEFAULT_PROJECTS_SCHEMAS.previ) && (
                    <TabPanel key={DEFAULT_PROJECTS_SCHEMAS.previ} value={DEFAULT_PROJECTS_SCHEMAS.previ}>
                      {getTabContent('DeFi Prévi')}
                    </TabPanel>
                  )}
                  {isTabVisible(DEFAULT_PROJECTS_SCHEMAS.staff) && (
                    <TabPanel key={DEFAULT_PROJECTS_SCHEMAS.staff} value={DEFAULT_PROJECTS_SCHEMAS.staff}>
                      <ComingSoonTabPanel />
                    </TabPanel>
                  )}
                  {isTabVisible(DEFAULT_PROJECTS_SCHEMAS.z) && (
                    <TabPanel key={DEFAULT_PROJECTS_SCHEMAS.z} value={DEFAULT_PROJECTS_SCHEMAS.z}>
                      {getTabContent('DeFi Z')}
                    </TabPanel>
                  )}
                </>
              ) : (
                alphabeticallySortedReports?.map(r => (
                  <TabPanel key={r.report_id} value={r.report_id}>
                    {getTabContent(r.report_name)}
                  </TabPanel>
                ))
              )}
            </ReportTabs>

            <CreateProjectModal
              open={createProjectModalOpen}
              onClose={() => {
                setCreateProjectModalOpen(false);
              }}
            />

            <MaximumNumberOfProjectsModal
              isOpen={maximumNumberOfProjectsReachedModalOpen}
              onClose={() => {
                setMaximumNumberOfProjectsReachedModalOpen(false);
              }}
            />

            {(rightClickedWorkspace || rightClickedReportTab) && <ManageWorkspaceOrReportUsersModal isWorkspaceUsersList={rightClickedWorkspace !== null} />}

            {projectsContext.selectedProject && (
              <>
                <UpdateProjectModal
                  open={projectsContext.updateProjectModalOpen}
                  onClose={() => {
                    projectsContext.setUpdateProjectModalOpen(false);
                    closeProjectMenu();
                  }}
                />
                <ManageProjectUsersModal
                  onClose={() => {
                    projectsContext.setManageProjectUsersModalOpen(false);
                    closeProjectMenu();
                  }}
                  open={projectsContext.manageProjectUsersModalOpen}
                />

                <DeleteProjectModal
                  onClose={() => {
                    projectsContext.setDeleteProjectModalOpen(false);
                    closeProjectMenu();
                  }}
                  open={projectsContext.deleteProjectModalOpen}
                />
              </>
            )}
          </ContentContainer>
        </>
      )}
    </PageContainer>
  );
};

export default Folders;
