import dayjs from 'dayjs';

const getOrderedFiscalMonths = firstFiscalMonth => {
  const defaultMonths = [...Array(12)].map((_, i) => i + 1);
  if (firstFiscalMonth === 1) {
    return {
      previousYearMonths: [],
      currentYearMonths: defaultMonths
    };
  }
  const monthsFromFirstFiscalMonth = defaultMonths.splice(firstFiscalMonth - 1);
  const monthsBeforeFirstFiscalMonth = defaultMonths.splice(0, firstFiscalMonth - 1);

  return {
    previousYearMonths: monthsFromFirstFiscalMonth,
    currentYearMonths: monthsBeforeFirstFiscalMonth
  };
};

const getFiscalYearColumns = (firstFiscalMonth, year) => {
  const orderedMonths = getOrderedFiscalMonths(firstFiscalMonth);
  const previousYear = (year - 1).toString();

  const currentYearColumns = orderedMonths.currentYearMonths.map(monthNumber => {
    const monthString = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
    return `${year}-${monthString}`;
  });
  const previousYearColumns = orderedMonths.previousYearMonths.map(monthNumber => {
    const monthString = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
    return `${previousYear}-${monthString}`;
  });
  return [...previousYearColumns, ...currentYearColumns];
};

const getHeaderRowCells = (budgetYears, expandedColumns, firstFiscalMonth, areRatiosActivated = false) => {
  const firstCell = {type: 'header', text: 'Libellés', className: 'first-column-title'};
  let cells = [firstCell];
  // eslint-disable-next-line no-restricted-syntax
  for (const year of budgetYears) {
    let monthsCells = [];
    const ratioId = `Ratio ${year}`;
    const isYearExpanded = expandedColumns.includes(year);
    if (isYearExpanded) {
      const columns = getFiscalYearColumns(firstFiscalMonth, year, budgetYears);
      monthsCells = columns.map(columnName => {
        return {
          type: 'chevron',
          nonEditable: true,
          text: columnName,
          parentId: year,
          className: 'cell-header-budget-month'
        };
      });
    }
    if (areRatiosActivated) {
      monthsCells.unshift({
        type: 'chevron',
        nonEditable: true,
        hasChildren: true,
        isExpanded: isYearExpanded,
        text: ratioId,
        className: 'cell-header-budget cell-header-budget-ratio'
      });
    }
    monthsCells.unshift({
      type: areRatiosActivated ? 'text' : 'chevron',
      hasChildren: !areRatiosActivated,
      nonEditable: true,
      isExpanded: !areRatiosActivated ? isYearExpanded : false,
      text: year,
      className: 'cell-header-budget cell-header-budget-year'
    });
    cells = [...cells, ...monthsCells];
  }
  return cells;
};

const getBudgetHeaderRow = (budgetYears, expandedColumns, firstFiscalMonth, isRatiosColumnVisible) => ({
  rowId: 'header',
  height: 40,
  cells: getHeaderRowCells(budgetYears, expandedColumns, firstFiscalMonth, isRatiosColumnVisible)
});

const getColumnsNames = (budgetYears, expandedColumns, firstFiscalMonth, isRatiosColumnVisible) => {
  let columnsNames = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const year of budgetYears) {
    let yearColumns = [];
    if (expandedColumns.includes(year)) {
      yearColumns = getFiscalYearColumns(firstFiscalMonth, year, budgetYears);
    }

    if (isRatiosColumnVisible) {
      yearColumns.unshift(year); // Two columns have the year ID
    }
    yearColumns.unshift(year);
    columnsNames = [...columnsNames, ...yearColumns];
  }
  columnsNames.unshift('Libellés');
  return columnsNames;
};

const getDistinctBudgetYearsFromData = data => {
  const budgetYears = [];

  if (data && data.length > 0) {
    const randomEntry = data[0];

    const properties = Object.keys(randomEntry);
    const datesProperty = properties.filter(p => p.includes('-01'));
    datesProperty.forEach(property => {
      const year = property.substring(0, 4);
      if (!budgetYears.includes(year)) {
        budgetYears.push(year);
      }
    });
  }

  return budgetYears;
};

const getPossibleBudgetYears = alreadyExistingBudgetYears => {
  let possibleBudgetYears = [];
  // Business rule : user can create a budget for year n+3 max and n-1 min
  for (let i = 0; i < 5; i++) {
    const dateYear = dayjs().year() - 1;
    const y = dateYear + i;
    possibleBudgetYears.push(y);
  }
  possibleBudgetYears = possibleBudgetYears.filter(y => !alreadyExistingBudgetYears.includes(y.toString()));
  return possibleBudgetYears;
};

const getTotalColumnCssClasses = (total, isYear) => {
  const baseCssClass = isYear ? 'bold cell-total-year' : 'bold cell-total-month';
  const negativeNumberCssClass = total < 0 ? 'negative' : '';

  return `${baseCssClass} ${negativeNumberCssClass}`;
};

const getMonthTotalCellCssClasses = (rowId, rowDepth, monthTotal, expandedRows) => {
  const isCalculatedCell = rowDepth === 1 || rowDepth === 2;

  const baseCssClass = isCalculatedCell ? 'cell-total-month' : '';
  const negativeNumberCssClass = monthTotal < 0 ? 'negative' : '';
  let backgroundCssClass = '';
  let boldCssClass = '';

  if (rowDepth === 3) {
    backgroundCssClass = rowId % 2 === 0 ? 'constrast-bg-cell' : 'white-bg-cell';
  }
  if (rowDepth !== 3) {
    boldCssClass = expandedRows.find(r => r === rowId) ? 'bold' : '';
  }

  return `${baseCssClass} ${boldCssClass} ${backgroundCssClass} ${negativeNumberCssClass}`;
};

const getYearTotalCellCssClasses = (year, yearTotal, expandedYears) => {
  const baseCssClass = 'cell-total-year';
  const boldCssClass = expandedYears.includes(year) ? 'bold' : '';
  const negativeNumberCssClass = yearTotal < 0 ? 'negative' : '';

  return `${baseCssClass} ${boldCssClass} ${negativeNumberCssClass}`;
};

export {
  getBudgetHeaderRow,
  getYearTotalCellCssClasses,
  getMonthTotalCellCssClasses,
  getTotalColumnCssClasses,
  getDistinctBudgetYearsFromData,
  getPossibleBudgetYears,
  getFiscalYearColumns,
  getColumnsNames
};
