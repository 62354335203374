import React from 'react';

import {Grid} from '@mui/material';
import Lottie from 'lottie-react';

import useTranscript from '../../../hooks/providers/useTranscript';
import soundWaveAnimation from '../../../lotties/sound-waves.json';
import CancelRecordingButton from '../CancelRecordingButton/CancelRecordingButton';
import SaveRecordButton from '../SaveRecorderButton/SaveRecordButton';

const AudioRecorder = () => {
  const {isRecording} = useTranscript();
  if (!isRecording) return null;

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item ml={8.5}>
          <Lottie id="audio-waves-lottie-animation" loop animationData={soundWaveAnimation} />
        </Grid>
        <Grid item ml={3}>
          <CancelRecordingButton />
        </Grid>
      </Grid>
      <SaveRecordButton />
    </>
  );
};

export default AudioRecorder;
