import {Close} from '@mui/icons-material';
import {Box, Chip, Grid} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';
import {string, arrayOf, func, bool} from 'prop-types';

import useProfile from '../../../hooks/providers/useProfile';
import {userShape} from '../../../proptypes-shapes';
import TitleWithTooltip from '../../project/TitleWithTooltip';

const UsersContainer = muiStyled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  listStyle: 'none',
  padding: '8px'
}));

const WorkspaceOrReportUsersList = ({isWorkspaceUsersList, readonly = false, title, titleTooltip, users, onChipDelete, isSubmitting}) => {
  const {profile} = useProfile();

  const isCurrentUserOnlyOwner = (users || []).length === 1 && users[0].user_id.toLowerCase() === profile.id;
  const isLastUserOfWorkspace = isWorkspaceUsersList && isCurrentUserOnlyOwner;

  const deleteUser = userId => {
    onChipDelete(userId);
  };

  if ((users || []).length === 0) {
    return null;
  }

  return (
    <Box mb={2}>
      <Grid container alignItems="center" mb={2}>
        <TitleWithTooltip title={title} tooltip={titleTooltip} />
      </Grid>

      <UsersContainer>
        {users.map(({username, user_id: userId}, index) => (
          <Chip
            disabled={isSubmitting || isLastUserOfWorkspace || readonly}
            sx={{mr: 1, mb: 1, cursor: 'pointer'}}
            key={index}
            color="primary"
            variant="outlined"
            label={username?.toLowerCase()}
            {...(readonly
              ? {}
              : {
                  onDelete: onChipDelete ? () => deleteUser(userId) : undefined,
                  deleteIcon: <Close fontSize="small" color="primary" />
                })}
          />
        ))}
      </UsersContainer>
    </Box>
  );
};

WorkspaceOrReportUsersList.defaultProps = {
  onChipDelete: null,
  readonly: false
};

WorkspaceOrReportUsersList.propTypes = {
  title: string.isRequired,
  titleTooltip: string.isRequired,
  users: arrayOf(userShape).isRequired,
  onChipDelete: func,
  isSubmitting: bool.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  readonly: bool,
  isWorkspaceUsersList: bool.isRequired
};

export default WorkspaceOrReportUsersList;
